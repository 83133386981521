import React, { useState } from 'react';
import Pagination from './Pagination';

const TableComponent = ({ data }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    if (!data || !data.tableData) {
        return <div className="modal">
        <div className="loading-modal-content">
            <div className="spinner"></div>
            <p>Loading...</p>
        </div>
    </div>
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.tableData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <table className="custom-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Value</th>
                        <th>Timestamp</th>
                        <th>Job Title</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.value}</td>
                            <td>{item.timestamp ? new Date(item.timestamp).toLocaleString() : 'N/A'}</td>
                            <td>{item.job_title || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={data.tableData.length}
                paginate={paginate}
                currentPage={currentPage}
            />
        </div>
    );
};

export default TableComponent;
