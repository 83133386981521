import React from 'react';
import { PieChart, Pie, BarChart, Bar, XAxis, YAxis, Tooltip, Cell } from 'recharts';
import './ChartComponent.css';

// Define colors for the pie charts, updated with complementary colors similar to #8784d8
const pieChartColors = ['#8784d8', '#9f7fe5', '#b29cf2', '#c6baf8', '#d9d8fc', '#f2e3ff', '#d8c8ff'];

const ChartComponent = ({ data }) => {
    return (
        <div className="dashboard-container">
            {/* Existing Job Title Pie Chart */}
            <div className="card chart-item">
                <h3>Job Title</h3>
                <PieChart width={400} height={400}>
                    <Pie 
                        data={data.pieDataJobTitles} 
                        dataKey="value" 
                        nameKey="name" 
                        cx="50%" 
                        cy="50%" 
                        outerRadius={100} 
                        fill="#8784d8" 
                        label={(entry) => `${entry.name} ${entry.value.toFixed(2)}%`} 
                    >
                        {data.pieDataJobTitles.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={pieChartColors[index % pieChartColors.length]} />
                        ))}
                    </Pie>
                    <Tooltip 
                        formatter={(value) => `${value.toFixed(2)}%`} 
                        labelFormatter={(label) => `${label}`} 
                    />
                </PieChart>
            </div>
            
            {/* Existing User Amount Bar Chart */}
            <div className="card chart-item">
                <h3>User Amount</h3>
                <BarChart width={600} height={300} data={data.barData}>
                    <XAxis dataKey="monthYear" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#8784d8" />
                </BarChart>
            </div>

            {/* Existing Industry Pie Chart */}
            <div className="card chart-item">
                <h3>Industry</h3>
                <PieChart width={400} height={400}>
                    <Pie 
                        data={data.pieDataIndustries} 
                        dataKey="value" 
                        nameKey="name" 
                        cx="50%" 
                        cy="50%" 
                        outerRadius={100} 
                        fill="#8784d8" 
                        label={(entry) => `${entry.name} ${entry.value.toFixed(2)}%`} 
                    >
                        {data.pieDataIndustries.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={pieChartColors[index % pieChartColors.length]} />
                        ))}
                    </Pie>
                    <Tooltip 
                        formatter={(value) => `${value.toFixed(2)}%`} 
                        labelFormatter={(label) => `${label}`} 
                    />
                </PieChart>
            </div>

            {/* Additional Components */}
            {/* Top Sellers */}
            <div className="card top-sellers-card">
                <h3>Top Users</h3>
                <ul>
                    {data.topSellers && data.topSellers.length > 0 ? (
                        data.topSellers.map(seller => (
                            <li key={seller.name}>{seller.name}: ${seller.sales}</li>
                        ))
                    ) : (
                        <li>No Users available</li>
                    )}
                </ul>
            </div>

            {/* Top Products */}
            <div className="card top-products-card">
                <h3>Top Industries</h3>
                <ul>
                    {data.topProducts && data.topProducts.length > 0 ? (
                        data.topProducts.map(product => (
                            <li key={product.name}>{product.name}: ${product.sales}</li>
                        ))
                    ) : (
                        <li>No industries available</li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default ChartComponent;
