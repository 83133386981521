import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const API_URL = process.env.REACT_APP_API_URL;

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate(); // Use navigate for navigation to sign-up or login
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await fetch(`${API_URL}/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: username,
                    password: password,
                }),
            });
    
            const result = await response.json();
    
            if (response.ok && result.success) {
                navigate('/dashboard');
            } else {
                alert('Login failed: ' + result.message);
            }
        } catch (error) {
            console.error('Error during authentication:', error);
            alert('An error occurred while trying to authenticate');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                {/* Centered logo */}
                <div className="logo-container">
                    <img src="https://cdn.prod.website-files.com/66bbd75ce637215d048c9c3d/66c3f8f0a94447109749d5d3_android-chrome-512x512.png" alt="Logo" className="logo" />
                </div>

                <h2>Login</h2>

                <div className="form-group">
                    <label>Username</label>
                    <input
                        type="text"
                        placeholder="Enter your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <div className="password-wrapper">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="show-password-button"
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                </div>

                <button type="submit">Login</button>

                <p className="disclaimer">
                    New to Demystify AI InstaBot? <br/> <a href="/">Get your AI Assistant within 1 minute!</a>
                </p>
            </form>
        </div>
    );
};

export default Login;
