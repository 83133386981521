import axios from 'axios';
export const API_URL = process.env.REACT_APP_API_URL;

export const createNewThread = async (url) => {
    try {
        let response = await fetch(`${API_URL}/api/new/${url}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json(); // Parses the JSON response
    } catch (err) {
        console.log(err.message);
        throw err; // Rethrow to let caller handle the error too
    }
}


export const createNewUser = async (userData) => {
    try {
        let response = await fetch(`${API_URL}/api/new_user`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData) // Sending the user data as JSON
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json(); // Parses the JSON response
    } catch (err) {
        console.error("Error creating new user:", err.message);
        throw err; // Rethrow to let caller handle the error too
    }
}

export const fetchThread = async (threadId) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}`)
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const fetchRun = async (threadId, runId) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}/runs/${runId}`)
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const postMessage = async (threadId, message) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                content: message,
            })
        })
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const postToolResponse = async (threadId, runId, toolResponses) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}/runs/${runId}/tool`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(toolResponses)
        })
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

// export const postUrlForTraining = async (url) => {
//     try {
//         const response = await fetch(`${API_URL}/train_only`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json"
//             },
//             body: JSON.stringify({ url })
//         });
//         return response.json();
//     } catch (err) {
//         console.error("Error during Firecrawl process:", err.message);
//     }
// }

export const fetchStatistics = () => {
    return axios.get(`${API_URL}/statistics`);
}

