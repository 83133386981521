import React, { useEffect, useState } from 'react';
import { fetchStatistics } from '../../services/api';
import ChartComponent from './ChartComponent';
import TableComponent from './TableComponent';

const DashBoard = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchStatistics();
                
                // Log the response to inspect its structure
                console.log('API Response:', response);

                if (response && response.data) {
                    const users = response.data.users || [];
                    const chatLogs = response.data.chat_logs || [];

                    // Format the response data to match the expected structure
                    const userCountByJobTitle = users.reduce((acc, user) => {
                        const jobTitle = user.job_title || 'Unknown';
                        const existing = acc.find(item => item.name === jobTitle);
                        if (existing) {
                            existing.value += 1;
                        } else {
                            acc.push({ name: jobTitle, value: 1 });
                        }
                        return acc;
                    }, []);

                    // Calculate percentages
                    const totalUsers = userCountByJobTitle.reduce((sum, item) => sum + item.value, 0);
                    let pieDataJobTitles = userCountByJobTitle.map(item => ({
                        name: item.name,
                        value: (item.value / totalUsers) * 100 // Convert to percentage
                    }));

                    // Filter out small percentages and combine them into "Other"
                    const threshold = 5;
                    const others = pieDataJobTitles.filter(item => item.value < threshold);
                    pieDataJobTitles = pieDataJobTitles.filter(item => item.value >= threshold);
                    
                    if (others.length > 0) {
                        const otherTotal = others.reduce((sum, item) => sum + item.value, 0);
                        pieDataJobTitles.push({ name: 'Other', value: otherTotal });
                    }

                    // Industry pie data
                    const industryCount = users.reduce((acc, user) => {
                        const industry = user.industry || 'Unknown';
                        const existing = acc.find(item => item.name === industry);
                        if (existing) {
                            existing.value += 1;
                        } else {
                            acc.push({ name: industry, value: 1 });
                        }
                        return acc;
                    }, []);
                    
                    const totalIndustries = industryCount.reduce((sum, item) => sum + item.value, 0);
                    let pieDataIndustries = industryCount.map(item => ({
                        name: item.name,
                        value: (item.value / totalIndustries) * 100 // Convert to percentage
                    }));

                    // Filter out small percentages and combine them into "Other"
                    const industryThreshold = 5;
                    const industryOthers = pieDataIndustries.filter(item => item.value < industryThreshold);
                    pieDataIndustries = pieDataIndustries.filter(item => item.value >= industryThreshold);
                    
                    if (industryOthers.length > 0) {
                        const otherTotal = industryOthers.reduce((sum, item) => sum + item.value, 0);
                        pieDataIndustries.push({ name: 'Other', value: otherTotal });
                    }

                    const formattedData = {
                        pieDataJobTitles,
                        barData: Object.entries(
                            users.reduce((acc, user) => {
                                const date = new Date(user.timestamp);
                                const month = date.getMonth() + 1; // Months are zero-indexed
                                const year = date.getFullYear();
                                const monthYear = `${year}-${month.toString().padStart(2, '0')}`;

                                if (!acc[monthYear]) {
                                    acc[monthYear] = 0;
                                }
                                acc[monthYear] += 1;
                                return acc;
                            }, {})
                        ).map(([monthYear, count]) => ({
                            monthYear,
                            count
                        })),
                        pieDataIndustries,
                        tableData: users.map(user => ({
                            id: user.id,
                            name: `${user.firstname} ${user.lastname}`,
                            value: user.email,
                            timestamp: user.timestamp,
                            job_title: user.job_title,
                            industry: user.industry
                        }))
                    };
                    
                    setData(formattedData);
                } else {
                    throw new Error('Unexpected API response structure');
                }
            } catch (error) {
                console.error('Error fetching statistics', error);
                setError('Failed to fetch data');
            }
        };
        fetchData();
    }, []);

    return (
        <div>
        {error ? (
            <div>{error}</div>
        ) : data ? (
            <div>
                <ChartComponent data={data} />
                <TableComponent data={data} />
            </div>
        ) : (
            <div className="modal">
                <div className="loading-modal-content">
                    <div className="spinner"></div>
                    <p>Loading...</p>
                </div>
            </div>
        )}
    </div>
    );
};

export default DashBoard;
