import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Main.css';

const Success = () => {
    const navigate = useNavigate(); // Use navigate for navigation
    var ownerDetails = getOwnerDetailsFromLocalStorage();

    return (
        <div className="content">
            <h1>Thank you for registering!</h1>
            <p>You will be receiving an email soon for more details. In the meantime, feel free to go back to chat with your bot or purchase it with the links below.</p>
            <div className="button-container">
                <button className="back-to-chat-button" onClick={() => navigate('/onboarding?url=' + ownerDetails.websiteUrl + "")}>
                    Back to Chat
                </button>
                <button className="buy-now-button" onClick={() => (window.location.href = 'https://buy.stripe.com/5kA00td6R5LN7le145')}>
                    Buy Now
                </button>
            </div>
        </div>
    );
};

function getOwnerDetailsFromLocalStorage() {
    const details = localStorage.getItem('ownerDetails');
    const defaultOwnerDetails = {
        firstName: "User",
        lastName: "Name",
        email: "user@test.org",
        url: "https://www.example.com"
    };

    if (details) {
        try {
            return JSON.parse(details);
        } catch (error) {
            console.error("Failed to parse owner details from local storage:", error);
            return defaultOwnerDetails;
        }
    }
    return defaultOwnerDetails;
}

export default Success;
