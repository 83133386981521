import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import SignUp from './pages/SignUp/SignUp';
import Login from './pages/Login/Login';
import Main from './pages/Main/Main';
import Onboarding from './pages/Main/Onboarding';
import Dashboard from './components/Dashboard/Dashboard';
import Register from './pages/Main/Register.jsx';
import Success from './pages/Main/Success.jsx';
import NotFound from './pages/Main/NotFound.jsx';  // Import the NotFound component

const App = () => {
    return (
        <div className="app-container">
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/register" element={<Register />} />
                <Route path="/success" element={<Success />} />
                <Route path="/login" element={<Login />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/dashboard" element={<Dashboard />} />
                
                {/* Catch-all route for undefined URLs */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
};

export default App;
