import React, { useState } from 'react';
import './SignUp.css';
export const API_URL = process.env.REACT_APP_API_URL;

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isVerified, setIsVerified] = useState(false);  // Track email verification
    const [passwordStrength, setPasswordStrength] = useState('');  // Track password strength
    const [errorMessage, setErrorMessage] = useState('');

    // Function to handle password strength checking
    const checkPasswordStrength = (password) => {
        const strongRegex = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})"
        );
        if (strongRegex.test(password)) {
            setPasswordStrength("Strong");
        } else {
            setPasswordStrength("Weak");
        }
    };

    // Function to handle sign-up form submission
    const handleSignUp = async (e) => {
        e.preventDefault();

        // Validate if passwords match
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match");
            return;
        }

        // Validate if password strength is strong
        if (passwordStrength === "Weak") {
            setErrorMessage("Password is too weak. Use upper and lower case letters, numbers, and special characters.");
            return;
        }

        // Send email verification request to backend
        try {
            const response = await fetch(`${API_URL}/send-verification-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();

            if (response.ok) {
            } else {
                setErrorMessage(data.detail);
            }
        } catch (error) {
            setErrorMessage('Failed to send verification email. Please try again later.');
        }
    };

    return (
        <div className="signup-container">
            {!isVerified ? (
                <form className="signup-form" onSubmit={handleSignUp}>
                    <h2>Sign Up</h2>

                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                checkPasswordStrength(e.target.value);
                            }}
                            placeholder="Enter your password"
                            required
                        />
                        <span>Password strength: {passwordStrength}</span>
                    </div>

                    <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm your password"
                            required
                        />
                    </div>

                    {errorMessage && <p className="error-message">{errorMessage}</p>}

                    <button type="submit">Sign Up</button>
                    {/* <p className="disclaimer">
                        Already have an account?<a href="/login"> Sign In</a>
                    </p> */}
                </form>
            ) : (
                <div className="verification-message">
                    <h2>Verify Your Email</h2>
                    <p>Please check your email and click on the verification link to verify your account.</p>
                </div>
            )}
        </div>
    );
};

export default SignUp;