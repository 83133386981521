import React from 'react';
import './LoadingScreen.css';  // Add custom styles here

function LoadingScreen() {
    return (
        <div className="loading-screen">
            <h2>Creating AI Assistant</h2>
            <p>We are going to scan your website and create a tailored AI Assistant in about 10 seconds.</p>
            <div className="loading-circle">
                <span>0%</span>
            </div>
        </div>
    );
}

export default LoadingScreen;
