import React, { useState } from 'react';
import '../SignUp/SignUp.css';
import { useNavigate } from 'react-router-dom';
export const API_URL = process.env.REACT_APP_API_URL;

const Register = () => {
    const [ownerDetails, setOwnerDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        websiteUrl: '',
    });
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // State to manage button disabled status
    const [isLoading, setIsLoading] = useState(false); // State to manage loading modal visibility
    const navigate = useNavigate(); // Use navigate for navigation to sign-up or login

    // Function to validate phone number format (US phone number example)
    const isValidPhoneNumber = (number) => {
        const phoneRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
        return phoneRegex.test(number);
    };

    // Function to format phone number
    const formatPhoneNumber = (value) => {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return value;
    };

    // General handler to update the ownerDetails state object
    const handleDetailChange = (field) => (event) => {
        setOwnerDetails((prevDetails) => ({
            ...prevDetails,
            [field]: event.target.value,
        }));
    };

    // Function to handle sign-up form submission
    const handleSignUp = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button on click
        setIsLoading(true); // Show loading modal
        localStorage.setItem('isRegistered', 1);

        // Validate if terms and conditions are accepted
        if (!termsAccepted) {
            setErrorMessage('You must accept the terms and conditions.');
            setIsSubmitting(false); // Re-enable the button
            setIsLoading(false); // Hide loading modal
            return;
        }

        // Validate phone number if provided
        if (ownerDetails.phone && !isValidPhoneNumber(ownerDetails.phone)) {
            setErrorMessage('Please enter a valid phone number.');
            setIsSubmitting(false); // Re-enable the button
            setIsLoading(false); // Hide loading modal
            return;
        }

        // Retrieve existing data from localStorage if available
        const storedOwnerDetails = JSON.parse(localStorage.getItem('ownerDetails')) || {};

        // Update only the FirstName, LastName, and Email fields, while keeping other fields intact
        const updatedOwnerDetails = {
            ...storedOwnerDetails, // Keep existing details
            firstName: ownerDetails.firstName,
            lastName: ownerDetails.lastName,
            email: ownerDetails.email,
        };

        // Store updated ownerDetails in localStorage
        localStorage.setItem('ownerDetails', JSON.stringify(updatedOwnerDetails));

        // Send ownerDetails to the /register_user API endpoint
        try {
            const response = await fetch(`${API_URL}/register_user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName: ownerDetails.firstName,
                    lastName: ownerDetails.lastName,
                    email: ownerDetails.email,
                    websiteUrl: localStorage.getItem('websiteUrl'),
                }),
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.detail);
            }
        } catch (error) {
            setErrorMessage('Failed to register user. Please try again later.');
            setIsSubmitting(false); // Re-enable the button
            setIsLoading(false); // Hide loading modal
            return;
        }

        // Send email welcome request to backend
        try {
            const response = await fetch(`${API_URL}/send-welcome-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: ownerDetails.email,
                    phone: ownerDetails.phone ? ownerDetails.phone : null,
                }),
            });
            const data = await response.json();

            if (response.ok) {
                navigate('/success');
            } else {
                setErrorMessage(data.detail);
            }
        } catch (error) {
            setErrorMessage('Failed to send welcome email. Please try again later.');
        } finally {
            setIsSubmitting(false); // Re-enable the button after response
            setIsLoading(false); // Hide loading modal
        }
    };

    return (
        <div className="signup-container">
            {isLoading && <div className="modal">
                    <div className="loading-modal-content">
                        <div className="spinner"></div>
                        <p>Signing you up...</p>
                    </div>
                </div>} {/* Display loading modal when loading */}
            <form className="signup-form" onSubmit={handleSignUp}>
                <h2>Register</h2>

                <div className="form-group">
                    <label>First Name *</label>
                    <input
                        type="text"
                        value={ownerDetails.firstName}
                        onChange={handleDetailChange('firstName')}
                        placeholder="Enter your first name"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Last Name *</label>
                    <input
                        type="text"
                        value={ownerDetails.lastName}
                        onChange={handleDetailChange('lastName')}
                        placeholder="Enter your last name"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Email *</label>
                    <input
                        type="email"
                        value={ownerDetails.email}
                        onChange={handleDetailChange('email')}
                        placeholder="Enter your email"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>
                        <input
                            type="checkbox"
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                        I agree to the <a className="underline" href="https://www.demystifyai.org/terms-and-condition" target="_blank" rel="noopener noreferrer">terms of service</a> *
                    </label>
                </div>

                {errorMessage && <p className="error-message">{errorMessage}</p>}

                <button type="submit" disabled={isSubmitting}>
                    Register
                </button>
            </form>
        </div>
    );
};

export default Register;