import React, { useState } from 'react';
import './Onboarding.css';
import Header from "../../components/Chat/Header";
import ChatInput from "../../components/Chat/ChatInput";
import ChatMessage from "../../components/Chat/ChatMessage";
import ChatStatusIndicator from "../../components/Chat/ChatStatusIndicator";
import Loading from "../../components/Chat/Loading";
import { useThread } from '../../hooks/useThread';
import { useRunPolling } from '../../hooks/useRunPolling';
import { useRunRequiredActionsProcessing } from '../../hooks/useRunRequiredActionsProcessing';
import { useRunStatus } from '../../hooks/useRunStatus';
import { postMessage } from "../../services/api";
import { useNavigate, useSearchParams  } from 'react-router-dom';

const Onboarding = () => {
    const [run, setRun] = useState(undefined);
    const [isSending, setIsSending] = useState(false);
    const { threadId, messages, setActionMessages, clearThread } = useThread(run, setRun);
    useRunPolling(threadId, run, setRun);
    useRunRequiredActionsProcessing(run, setRun, setActionMessages);
    const { status, processing } = useRunStatus(run);
    var ownerDetails = getOwnerDetailsFromLocalStorage();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const url = searchParams.get('url');

    let messageList = messages
        .toReversed()
        .filter((message) => message.hidden !== true)
        .map((message) => {
            return <ChatMessage
                message={message.content}
                role={message.role}
                key={message.id}
                firstName={ownerDetails.firstName ? ownerDetails.firstName : "User"}
                lastName={ownerDetails.lastName ? ownerDetails.lastName : ""}
                websiteUrl={ownerDetails.websiteUrl ? ownerDetails.websiteUrl : "https://www.example.com"}
            />
        });

    return (
        <div className="md:container md:mx-auto lg:px-32 h-screen bg-slate-700 flex flex-col">
            <Header onNewChat={clearThread} BotName={url} />
            <div className="flex flex-col-reverse grow overflow-scroll">
                {status !== undefined && <ChatStatusIndicator status={status} />}
                {processing && <Loading />}
                {messageList}
            </div>
            <div className="my-4">
                <ChatInput
                    onSend={(message) => {
                        setIsSending(true);  // Disable input before sending message
                        ownerDetails = getOwnerDetailsFromLocalStorage();
                        postMessage(threadId, message)
                            .then(response => {
                                setRun(response);  // Update run state
                            })
                            .finally(() => {
                                setIsSending(false);  // Re-enable input after sending is complete
                            });
                    }}
                    isSending={isSending}
                    disabled={processing || isSending}  // Disable input if already processing or sending
                />
            </div>
            {!getIsRegisteredFromLocalStorage() ? (
                <button className="toggle-signup-button" onClick={() => navigate('/register')}>
                    Register Now
                </button>
            ) : (
                <button className="toggle-signup-button" onClick={() => navigate('/success')}>
                    Back to Success Page
                </button>
            )}
        </div>
    );
};
function getIsRegisteredFromLocalStorage() {
    return localStorage.getItem('isRegistered') === '1';
}
function getOwnerDetailsFromLocalStorage() {
    const details = localStorage.getItem('ownerDetails');
    const defaultOwnerDetails = {
        firstName: "User",
        lastName: "Name",
        email: "user@test.org",
        url: "https://www.example.com"
    };

    if (details) {
        try {
            return JSON.parse(details);
        } catch (error) {
            console.error("Failed to parse owner details from local storage:", error);
            return defaultOwnerDetails;
        }
    }
    return defaultOwnerDetails;
}

export default Onboarding;
