import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Main.css';
import LoadingScreen from './LoadingScreen'; // Import your LoadingScreen component
import Onboarding from './Onboarding';  // Import OnboardingPage component
//import { postUrlForTraining } from '../../services/api'; // Simulated API call

const Main = () => {
    const [websiteUrl, setWebsiteUrl] = useState('');  // State for website URL input
    const [isLoading, setIsLoading] = useState(false); // State for showing/hiding loading screen
    const [isOnboarding, setIsOnboarding] = useState(false); // State to control onboarding display
    const [error, setError] = useState("");
    const navigate = useNavigate(); // Use navigate for navigation to sign-up or login
    let ownerDetails = getOwnerDetailsFromLocalStorage(); // Fetch initial owner details

    // Function to validate if the entered URL is a valid domain
    const isValidDomain = (url) => {
        // Regular expression to validate basic domain format
        const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}$/;
        return domainRegex.test(url);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form from refreshing the page
        setError(""); // Clear any previous errors

        // Check if the entered URL is valid
        if (!isValidDomain(websiteUrl)) {
            setError("Please enter a valid domain (e.g., https://example.com).");
            return;
        }

        setIsLoading(true); // Show loading screen

        try {
            // Simulate an API call with a delay (you can replace this with your actual API call)
            await new Promise((resolve) => setTimeout(resolve, 2000));

            // Simulated API call to post the URL for training
            //await postUrlForTraining(websiteUrl); // Add actual API logic here

            console.log("API call complete");
            console.log("Form submitted with website URL:", websiteUrl);

            // Update owner details with the website URL
            ownerDetails = { ...ownerDetails, websiteUrl }; // Update the owner details with the website URL
            saveOwnerDetailsToLocalStorage(ownerDetails); // Save updated owner details back to local storage

            // Set isOnboarding to true to display the onboarding component
            setIsOnboarding(true);
        } catch (error) {
            console.error("Error during submission: ", error);
            setError("An error occurred while submitting. Please try again.");
        } finally {
            // Hide loading screen
            setIsLoading(false);
        }
    };

    return (
        <div className="content">
            {isLoading ? (
                <LoadingScreen /> // Show loading screen when loading
            ) : isOnboarding ? (
                navigate('/onboarding?url=' + ownerDetails.websiteUrl + "") // Show onboarding component after loading is complete
            ) : (
                <>
                    <h1>Capture & Qualify Leads 24/7 with <span>AI Sales Assistant</span></h1>
                    <p>Automate pre-sales support, capture qualified leads, and free up your team's time for what matters most with Demystify AI – your 24/7 human-like chatbot.</p>

                    <form className="website-form" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="https://www.example.com"
                            value={websiteUrl}
                            onChange={(e) => setWebsiteUrl(e.target.value)} // Capture the input value
                            required
                        />
                        <button type="submit">TRY YOUR AI ASSISTANT FOR FREE</button>
                    </form>

                    {/* Display error message if validation fails */}
                    {error && <p style={{ color: "red" }}>{error}</p>}

                    {/* Optional login button */}
                    {/* <div className="auth-buttons">
                        <button className="toggle-login-button" onClick={() => navigate('/login')}>
                            Log In
                        </button>
                    </div> */}
                </>
            )}
        </div>
    );
};

// Fetch owner details from local storage, or return a default object if not found
function getOwnerDetailsFromLocalStorage() {
    const details = localStorage.getItem('ownerDetails');
    const defaultOwnerDetails = {
        firstName: "User",
        lastName: "Name",
        email: "user@test.org",
        websiteUrl: "" // Add a default for websiteUrl
    };

    if (details) {
        try {
            return JSON.parse(details);
        } catch (error) {
            console.error("Failed to parse owner details from local storage:", error);
            return defaultOwnerDetails;
        }
    }
    return defaultOwnerDetails;
}

// Save updated owner details to local storage
function saveOwnerDetailsToLocalStorage(ownerDetails) {
    try {
        //var thread_id = "thread_6GWlVhO4dymNbZbYWrDsZ9yi"
        localStorage.setItem('ownerDetails', JSON.stringify(ownerDetails));
        localStorage.setItem('websiteUrl', ownerDetails.websiteUrl);
        //localStorage.setItem('thread_id', thread_id); // Save to
        console.log("Owner details saved to local storage:", ownerDetails);
    } catch (error) {
        console.error("Failed to save owner details to local storage:", error);
    }
}

export default Main;